"use client";
import { NewsletterPopupStoryblok } from "@/types/storyblok-blok-types";
import * as Dialog from "@radix-ui/react-dialog";
import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";
import clsx from "clsx";
import NewsletterForm from "./NewsletterForm";
import styles from "./index.module.css";
import { useNewsletterPopup } from "./useNewsletterPopup";

export const NewsLetterPopupUI = ({
  blok,
}: {
  blok: NewsletterPopupStoryblok;
}) => {
  const { isPopupVisible, handleOpenChange, hasSubmitted } =
    useNewsletterPopup(blok);

  return (
    <div data-blok {...storyblokEditable(blok as unknown as SbBlokData)}>
      <Dialog.Root
        modal={false}
        open={isPopupVisible}
        onOpenChange={handleOpenChange}
      >
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content
          onInteractOutside={(e) => e.preventDefault()}
          className={clsx("dialog-content-fade-in", styles.modal)}
        >
          <Dialog.Close className={styles.close}>X</Dialog.Close>
          <NewsletterForm hasSubmitted={hasSubmitted} blok={blok} />
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
};
