"use client";
import BlokWrap from "@/components/atoms/BlokWrap";
import RichText from "@/components/atoms/RichText/RichText";
import { NewsletterPopupStoryblok } from "@/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

type NewsletterFormProps = {
  hasSubmitted: boolean;
  blok: NewsletterPopupStoryblok;
};

const NewsletterForm = ({ hasSubmitted, blok }: NewsletterFormProps) => {
  const { title, preamble, klaviyoId, titleSubscribed, preambleSubscribed } =
    blok;
  const titleToUse = hasSubmitted ? titleSubscribed : title;
  const textToUse = hasSubmitted ? preambleSubscribed : preamble;

  return (
    <BlokWrap data-layout="section" className={styles.root}>
      {titleToUse && <h3 className={clsx(styles.title)}>{titleToUse}</h3>}
      {textToUse && (
        <RichText className={clsx(styles.intro)}>{textToUse}</RichText>
      )}

      <div className={clsx(styles.container)}>
        <div className={`klaviyo-form-${klaviyoId}`}></div>
      </div>
    </BlokWrap>
  );
};
export default NewsletterForm;
