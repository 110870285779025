import { useSelection } from "@/lib/hooks/useSelection";
import { NewsletterPopupStoryblok } from "@/types/storyblok-blok-types";
import { usePathname } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import {
  hasClosedAndClosedOverXWeeksAgo,
  hasClosedFewerThanMaxTimes,
  IsNotLoggedInOrSubscribed,
} from "./conditions";
import { useAllTrue } from "./isAllTrue";
import { useTimeOut } from "./useTimeout";

type Subscribed = {
  hasSubscribed: boolean;
  closedAt?: string;
  closedCount: number;
  visitedPages: number;
};

const defaultSubscribed: Subscribed = {
  hasSubscribed: false,
  closedCount: 0,
  closedAt: "",
  visitedPages: 0,
};

interface KlaviyoFormEventDetail {
  companyId: string;
  formId: string;
  formVersionId: string;
  metaData?: any;
  type: string;
}

interface KlaviyoFormEvent extends CustomEvent {
  detail: KlaviyoFormEventDetail;
}

export const setLocalSubscribedState = (subscribed: Subscribed) => {
  localStorage.setItem("newsletterSubscribed", JSON.stringify(subscribed));
};

/* Get local storage token */
export const getLocalSubscribeState = () => {
  try {
    const newsletterToken = localStorage.getItem("newsletterSubscribed");
    return newsletterToken ? JSON.parse(newsletterToken) : defaultSubscribed;
  } catch (e) {
    return defaultSubscribed;
  }
};
export const useNewsletterPopup = (blok: NewsletterPopupStoryblok) => {
  const pathname = usePathname();
  const popupDelaySeconds = useTimeOut(Number(blok?.popupDelay));
  const useSecondsDelay = blok?.useSecondsDelay;
  const { loggedIn } = useSelection();
  const [subscribedState, setSubscribedState] = useState<Subscribed>(
    getLocalSubscribeState(),
  );
  const [pagesVisited, setPagesVisited] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  // This checks if the delay of the popup should be based on seconds, or page visits.
  // If useSecondsDelay is true in Storyblok, we will display the popup based on seconds
  // Else, we will display the popup based on page visisted
  const popDelay = !!useSecondsDelay
    ? popupDelaySeconds
    : pagesVisited >= Number(blok?.pagesVisited);

  // Conditions for popup visibility
  const conditions = useMemo(
    () => [
      () =>
        IsNotLoggedInOrSubscribed({
          loggedIn,
          loggedInSubscribed: loggedIn?.newsletter || false,
        }),
      () =>
        hasClosedAndClosedOverXWeeksAgo(
          new Date(subscribedState?.closedAt || ""),
          subscribedState?.closedCount,
          Number(blok?.popupHiddenDuration),
        ),
      () => hasClosedFewerThanMaxTimes(subscribedState?.closedCount, 1),
    ],
    [loggedIn, subscribedState, blok?.popupHiddenDuration],
  );

  const allIsTrue = useAllTrue(conditions);

  // Determine popup visibility
  // Quirky check - if the end user submits the form, we want
  // to display the "Welcome!"-message, meaning we cant hide the form even though end user has subscribed
  // If the end user does not submit the form, it will determine visibility on subscribedState
  const isPopupVisible =
    modalOpen && allIsTrue && popDelay && hasSubmitted
      ? hasSubmitted
      : !subscribedState.hasSubscribed && modalOpen && allIsTrue && popDelay;

  // Update visited pages on path change
  useEffect(() => {
    if (
      subscribedState?.visitedPages > Number(blok?.pagesVisited) &&
      popupDelaySeconds
    )
      return;
    setPagesVisited((previous: number) => {
      const newVisitedPages = previous + 1;
      const updatedState = {
        ...subscribedState,
        visitedPages: newVisitedPages,
      };
      setSubscribedState(updatedState);
      setLocalSubscribedState(updatedState);
      return newVisitedPages;
    });
  }, [pathname]);

  // Handle Klaviyo form submit event
  useEffect(() => {
    const handleKlaviyoSubmit = (e: Event) => {
      const klaviyoEvent = e as KlaviyoFormEvent;
      if (
        klaviyoEvent.detail.type === "submit" ||
        klaviyoEvent.detail.type === "stepSubmit"
      ) {
        const newSubscribeState = { ...subscribedState, hasSubscribed: true };
        setSubscribedState(newSubscribeState);
        setLocalSubscribedState(newSubscribeState);
        setHasSubmitted(true);
      }
    };

    window.addEventListener("klaviyoForms", handleKlaviyoSubmit);

    return () =>
      window.removeEventListener("klaviyoForms", handleKlaviyoSubmit);
  }, [subscribedState]);

  // Handle modal open/close state changes
  const handleOpenChange = (open: boolean) => {
    setModalOpen(open);
    if (!open) {
      const newSubscribeState = {
        ...subscribedState,
        closedAt: new Date().toISOString(),
        closedCount: subscribedState.closedCount + 1,
      };
      setSubscribedState(newSubscribeState);
      setLocalSubscribedState(newSubscribeState);
    }
  };

  return { isPopupVisible, handleOpenChange, hasSubmitted };
};
