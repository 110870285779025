export const IsNotLoggedInOrSubscribed = ({
  loggedIn,
  loggedInSubscribed,
}: {
  loggedIn: any;
  loggedInSubscribed: boolean;
}): boolean => {
  return !loggedIn || (!!loggedIn && !loggedInSubscribed);
};

/**
 * Checks if its been over X amount of weeks since modal was closed.
 * @param modalLastClosedDate - Last time the popup modal was closed
 * @param amountOfWeeks - Variable to compare against
 * @returns true if it's been over X amount of weeks since modal was closed,
 * else false
 */
export const isOverXWeeksAgo = (
  modalLastClosedDate: Date,
  amountOfWeeks: number,
): boolean => {
  const TODAYS_DATE = new Date();
  const ONE_WEEK_IN_DAYS = 7;
  const WEEKS_POPUP_HIDDEN = ONE_WEEK_IN_DAYS * amountOfWeeks;
  const TWO_WEEKS = new Date(
    TODAYS_DATE.setDate(TODAYS_DATE.getDate() - WEEKS_POPUP_HIDDEN),
  );
  const isOlderThanTwoWeeks =
    new Date(modalLastClosedDate).getTime() < TWO_WEEKS.getTime();
  return isOlderThanTwoWeeks;
};

/**
 * Checks if the modal is registered closed, and if its over X amount of weeks ago.
 * @param modalLastClosedDate - Last time the popup modal was closed
 * @param closedCount - Counter of popup closed
 * @param amountOfWeeks -
 * @returns true if popup modal is closed and it was closed over X amount of weeks ago.
 */
export const hasClosedAndClosedOverXWeeksAgo = (
  modalLastClosedDate: Date,
  closedCount: number,
  amountOfWeeks: number,
): boolean => {
  return modalLastClosedDate && closedCount >= 3
    ? isOverXWeeksAgo(new Date(modalLastClosedDate), amountOfWeeks)
    : true;
};

/**
 * Checks if the modal has been closed fewer times than the allowed limit.
 * @param closedCount - Number of times the modal has been closed
 * @param maxAllowedCloses - Maximum number of allowed modal closes
 * @returns true if closedCount is less than maxAllowedCloses
 */
export const hasClosedFewerThanMaxTimes = (
  closedCount: number,
  maxAllowedCloses: number,
): boolean => {
  return closedCount < maxAllowedCloses;
};
